<template>
    <div>
        <el-dialog :title="title" :visible.sync="addUserVisible"  width="800px"
                   :before-close="handleCancel" :close-on-click-modal="false" @open="openAddUser()">
              <div>
              当前已绑定机器人：
              <el-table
                :data="dataList"
                height="250"
                border
                style="width: 80%;margin-top: 20px;margin-left: 80px;">
                <el-table-column
                  prop="robotname"
                  label="机器人名称">
                </el-table-column>
                <el-table-column
                  prop="createdon"
                  label="绑定日期">
                </el-table-column>
                <el-table-column
                  prop="active"
                  label="机器人状态">
                </el-table-column>
              </el-table>
              </div>
              <div >
                <div style="margin-top: 30px;">
                当前可添加机器人:
                   <div style="margin-bottom: 20px;margin-left: 80px;">
                      <el-button  round  v-for = "item in typelist" :key="item.id" style="width:130px;line-height:20px;margin-top:20px"  @click="spandatas(item.id)" >{{ item.typename }}</el-button>
                  </div>
                </div>
                <div style="margin-top: 15px;;margin-left: 80px;">
                  <el-checkbox-group v-model="checkAll">
                    <el-checkbox v-for = "item in datas" :key="item.id" :label="item.id" style="width:100px;float:center;margin-right:120px;line-height:20px;margin-top:20px" >{{ item.robotname }}</el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="open(checkAll)">提交申请</el-button>
                <el-button type="info" @click="handleCancel">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
  name: 'AddUnitsapply',
  props: {
    addUserVisible: Boolean,
    data: [Object, Boolean],
    title: String
  },
  data () {
    const generateData = _ => {
      const data = []
      for (let i = 1; i <= 15; i++) {
        data.push({
          key: i,
          label: `备选项 ${i}`,
          disabled: i % 4 === 0
        })
      }
      return data
    }
    return {
      checkAll: [],
      data1: generateData(),
      value: [1],
      value4: [1],
      renderFunc (h, option) {
        return <span>{ option.key } - { option.label }</span>
      },
      dataList: [],
      typelist: [],
      datas: []
    }
  },
  methods: {
    openAddUser () {
      this.axios
        .post('/units/unitbindingrobot/', {
          unitid: localStorage.getItem('unit_id')
        }).then((res) => {
          console.log('res', res.data.robot)
          this.dataList = res.data.data
          this.typelist = res.data.robot
        })
    },
    spandatas (typeid) {
      console.log('itemid', typeid)
      this.axios
        .post('/units/unitcanbindingrobot/', {
          unitid: localStorage.getItem('unit_id'),
          rtid: typeid
        }).then((res) => {
          console.log('res', res.data.data)
          this.datas = res.data.data
        })
    },
    // 关闭弹窗
    handleCancel () {
      this.$emit('cancel')
    },
    open (checkAll) {
      console.log('checkAll', checkAll)
      if (checkAll.length === 0) {
        console.log('为空', checkAll.length)
        this.$message({
          type: 'info',
          message: '请选择机器人！'
        })
      } else {
        this.$prompt('申请机器人原因', '提交申请', {
          confirmButtonText: '确认提交',
          cancelButtonText: '取消',
          inputPattern: '',
          inputErrorMessage: '内容不能为空',
          inputValidator: (value) => {
            if (!value) {
              return '输入不能为空'
            }
          }
        }).then(({ value }) => {
          this.axios
            .post('/units/insertunitrobot/', {
              userid: localStorage.getItem('user_id'),
              username: localStorage.getItem('yun_user'),
              unitid: localStorage.getItem('unit_id'),
              unitname: localStorage.getItem('unit_name'),
              rtid: checkAll,
              applycause: value
            }).then((res) => {
              this.$message({
                type: 'success',
                message: '提交申请成功，请等待管理员通过！'
              })
            })
          this.$emit('cancel')
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消提交申请'
          })
        })
      }
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style scoped>
     .Attopic{display:block; left: 56px; position: absolute; top: 68px; z-index: 999; background: #fff; border: 1px solid #ccc; padding: 2px; width: 180px; height: 100px; overflow-x:hidden; overflow-y:hidden;}

</style>
