import { render, staticRenderFns } from "./unitsapply.vue?vue&type=template&id=2acadbbb&scoped=true"
import script from "./unitsapply.vue?vue&type=script&lang=js"
export * from "./unitsapply.vue?vue&type=script&lang=js"
import style0 from "./unitsapply.vue?vue&type=style&index=0&id=2acadbbb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2acadbbb",
  null
  
)

export default component.exports